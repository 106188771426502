<template>
  <div class="systemOther">
    <div class="title">
      <span>系统设置 / </span> <span class="black">其他控制</span>
    </div>
    <el-card class="whiteboard">
      <el-row :gutter="24" class="flex_just_center">
        <el-col :sx="24" :md="12">
          <div class="shu" v-if="poewrMenuIdList[0].isok==1">咨询控制</div>
          <el-form label-width="90px" v-if="poewrMenuIdList[0].isok==1">
            <el-form-item label="线上咨询：" prop="region">
              <el-radio-group v-model="isok" @change="open">
                <el-radio v-for="(item, key) in typeList" :key="key" :label="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="text_y">
              <i class="el-icon-warning"></i>关闭线上咨询，即线上所有咨询师不可预约语音咨询和视频咨询，线下预约不受影响
            </div>
          </el-form>
          <div class="shu text_div">知情同意书</div>
          <div class="one">
            <el-form label-width="164px">
              <el-form-item label="团体标题：" style="margin-bottom:5px">
                <el-input type="text" v-model="group.bookTitle" placeholder="团体标题" :disabled="groupDisable"></el-input>
              </el-form-item>
              <el-form-item label="团体测评知情同意书：">
                <el-input type="textarea" v-model="group.bookText" :rows="4" placeholder="请输入团体测评之情同意书"
                  :disabled="groupDisable"></el-input>
              </el-form-item>
              <el-form-item v-if="poewrMenuIdList[1].isok==1">
                <el-button type="primary" size="small" @click="submit(group,'2')">保存</el-button>
                <el-button size="small" @click="groupDisable = false">编辑</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="one">
          <el-form label-width="164px">
            <el-form-item label="个体标题：" style="margin-bottom:5px">
              <el-input type="text" v-model="individual.bookTitle" placeholder="请输入个体标题 " :disabled="individualDisable">
              </el-input>
            </el-form-item>
            <el-form-item label="个体咨询知情同意书：">
              <el-input type="textarea" v-model="individual.bookText" :rows="4" placeholder="请输入个体咨询知情同意书"
                :disabled="individualDisable"></el-input>
            </el-form-item>
            <el-form-item v-if="poewrMenuIdList[1].isok==1">
              <el-button type="primary" size="small" @click="submit(individual,'1')">保存</el-button>
              <el-button size="small" @click="individualDisable = false">编辑</el-button>
            </el-form-item>
          </el-form>
          </div>
            <el-form label-width="164px">
            <el-form-item label="咨询标题：" style="margin-bottom:5px">
              <el-input type="text" v-model="consultation.bookTitle" placeholder="请输入咨询标题 " :disabled="consultationDisable">
              </el-input>
            </el-form-item>
            <el-form-item label="心理咨询预约协议:">
              <el-input type="textarea" v-model="consultation.bookText" :rows="4" placeholder="请输入心理咨询预约协议"
                :disabled="consultationDisable"></el-input>
            </el-form-item>
            <el-form-item v-if="poewrMenuIdList[1].isok==1">
              <el-button type="primary" size="small" @click="submit(consultation,'3')">保存</el-button>
              <el-button size="small" @click="consultationDisable = false">编辑</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import {
    consultbookList,
    xsIsok,
    consultbookUpdate,
    consultbookSave,
    counselorFindXs
  } from "../../../api/api";
  export default {
    data() {
      return {
        isok: "",
        typeList: [{
            value: "1",
            label: "开启",
          },
          {
            value: "0",
            label: "关闭",
          },
        ],
        
        groupDisable: true,
        individualDisable: true,
        consultationDisable: true,
        group: {},//团队
        individual: {}, //个体,
        consultation :{}//咨询协议书
      };
    },

    created() {
      this.displayOpen()
      this.getList()
    },
    computed:{
     poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList?this.$store.state.poewrMenuIdList:[]
     },
    },
    mounted() {},
    methods: {
      getList() {
        this.$request(consultbookList).then(
          (res) => {
            if (res.code === "200") {
              this.list = res.data
              this.individual = this.list.find(val => {
                if (val.bookType == 1) {
                  return val
                }
              })
              this.group = this.list.find(val => {
                if (val.bookType == 2) {
                  return val
                }
              })
              this.consultation = this.list.find(val => {
                if (val.bookType == 3) {
                  return val
                }
              })
            }
          }
        );
      },
      //显示是否开启
      displayOpen(){
         this.$request(counselorFindXs).then(
          (res) => {
            if (res.code === "200") {
             this.isok = res.data

            }
          }
        );
      },
      open() {
        this.$request(xsIsok(this.isok)).then(
          (res) => {
            if (res.code === "200") {
              this.$util.message("suc", "操作成功")

            }
          }
        );
      },
      //保存  
      submit(val, type) {
        let bookObj = {
          bookText: val.bookText,
          bookType: type,
          bookTitle: val.bookTitle,
          bookId: val.bookId

        }
        this.$request(val.bookText && val.bookTitle ? consultbookUpdate : consultbookSave, bookObj).then(
          (res) => {
            if (res.code === "200") {
              this.$util.message("suc", "操作成功")
              this.getList()
              this.individualDisable = true
              this.groupDisable = true
              this.consultationDisable = true
            }
          }
        );
      },

      handleSizeChange(val) {
        this.searchForm.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.searchForm.page = val
        this.getList()
      },
    },
  };
</script>

<style lang="less" scoped>
  .systemOther {
    height: 100%;

    .whiteboard {
      min-height: calc(100% - 64px);
    }

    .el-form-item {
      margin-bottom: 0px;
    }

    /deep/.el-card__body {
      padding: 32px;
    }

    .text_y {
      padding-left: 6px;
      line-height: 20px;
      color: #ffbf00ff;

      i {
        font-size: 20px;
      }
    }

    .text_div {
      margin: 32px 0px 16px 0px;
    }

    .one {
      margin-bottom: 32px;
    }
  }
</style>